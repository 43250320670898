body {
  background-color: #89CC04;
}

.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: arial_narrowregular, "Arial Narrow", sans-serif;
}

h1 {
  filter: blur(2px);
}

p {
  filter: blur(1.25px);
}

h2 {
  filter: blur(1.25px);
}

ul, span {
  filter: blur(0.75px);
}

input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
  background-color: #89CC04;
  filter: blur(0.75px);
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  filter: blur(0.75px);
  background-color: #89CC04;
}

ul {
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
  list-style-type: none;
  padding: 0;
}

.completed-screen {
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.completed-screen h2 {
  font-size: 2rem;
  font-weight: bold;
  margin: 10px 0;
}

.names-list {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(34, minmax(23px, 1fr));
  gap: 0px;
  list-style-type: none;
  padding: 0;
  flex-grow: 1;
  font-size: 0.9rem;
  max-height: calc(34 * 23px); /* Ensure the grid doesn't exceed 34 rows */
}

.names-list li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

@media (max-width: 768px) {
  .completed-screen h2 {
    font-size: 1.5rem;
  }

  .names-list {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .completed-screen h2 {
    font-size: 1.2rem;
  }

  .names-list {
    font-size: 0.6rem;
    grid-template-rows: repeat(34, minmax(12px, 1fr));
    max-height: calc(34 * 12px); /* Ensure the grid doesn't exceed 34 rows */
  }

  .completed-screen ul, span {
    filter: blur(0.0px);
  }

}

.share-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #89CC04;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.share-button:hover {
  background-color: #89CC04;
}